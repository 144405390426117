




import { Component, Vue } from "vue-property-decorator";

@Component
export default class LoginSuccessful extends Vue {
  // console.log(this.searchFor);
}
